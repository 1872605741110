.dataGridBox{
    height: 100%;
}

.dataGrid{
    min-height: 600px;
}

.previewImage{
    display: flex;
    margin: auto;
    max-height: 70px;
    max-width: 70px;
}

.dataGridTitle{
    padding-left: 10px;
}